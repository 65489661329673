/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { Menu as MenuIcon, Adb as AdbIcon } from '@mui/icons-material';
import logo from '../../assets/logo.webp';
import AmrSymbol from '../../assets/AMRSymbol.webp'
import AmrLogo from '../../assets/AmrLogo.png'
import './Navigation.css';

const pages = [
  {
    pageName: 'Our Concern',
    linksTo: '#ourConcern'
  },
  {
    pageName: 'The Statement',
    linksTo: 'http://amr.iapo-p4psobservatory.org/wp-content/uploads/2021/02/AMR-Patient-Consensus-Statement-finalDec2020.pdf'
  },
  {
    pageName: 'Call to Action',
    linksTo: '#callToAction'
  },
  {
    pageName: 'Advisory Group',
    linksTo: '#advisoryGroup'
  }
];

const Navigation = () => {
  // const classes = useStyles();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event: React.MouseEvent) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar sx={{
      backgroundColor: '#fff',
      minHeight: 'max-content'
    }} position="static" elevation={1}>
      <Container maxWidth="xl" sx={{
        flexWrap: 'wrap',
        alignItems: 'center',
        minHeight: 'max-content'
      }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem className="navItem" key={page.pageName} onClick={handleCloseNavMenu}>
                  <Typography variant='body1' textAlign="center">{page.pageName}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <img src={logo} alt='amrpatientalliance logo'
            style={{
              width: '23vw',
            }}
          />

          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', gap: 'clamp(8px, 15px, 20px)' } }}>
            {pages.map((page) => (
              <a 
                href={page.linksTo}
                target={page.linksTo.includes('http') ? '_blank' : '_self'}
                key={page.pageName}
                onClick={handleCloseNavMenu}
                style={{    
                  my: 2,
                  color: '#54595F',
                  fontWeight: '600',
                  display: 'block',
                  textDecoration: 'none',
                }}
              >
                {page.pageName}
              </a>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: 'flex', flexDirection: "row", alignItems: 'center', gap: '.3vw' }}>
            <Tooltip title="">
              {/* <Avatar sx={{
                  width: '75px',
                  height: 'auto',
                }} 
                alt="AMR Symbol"
                src={AmrSymbol}
              /> */}
              <img src={AmrSymbol} alt='AMR Symbol' style={{
                width: '4.3vw',
                height: '4.2vw',
              }} />
            </Tooltip>
            <Tooltip title="">
              {/* <Avatar
                variant="square"
                sx={{
                  width: 'auto',
                  height: '75px',
                }} 
                alt="AMR Logo"
                src={AmrLogo}
              /> */}
              <img src={AmrLogo} style={{
                width: '9.9vw',
                height: '4.3vw'
              }} alt='amrpatientalliance logo' />
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
};

export default Navigation;
