import React from 'react'
import { ThemeProvider, Typography } from '@mui/material';
import theme from '../../theme';
import { Container } from '@mui/system';

const TextSection = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container 
        id='ourConcern'
        style={{
          marginTop: '25px',
          display: 'flex',
          flexDirection: 'column',
          gap: '25px'
        }}
      >
        <Typography variant='h1' sx={{
          fontWeight: '600'
        }}>
          Our Concern
        </Typography>
        <Typography sx={{
          color: '#000 !important',
          // zgolemi go tekstot malce,
          fontSize: '1.1rem'
        }} variant='body1'>
          Antimicrobial therapies, including antibiotics, are powerful medicines that prevent and treat infections caused by pathogens including bacteria, fungi, viruses, and parasites. As with most medicines, these life-saving agents bring both benefits and risks, and must be used carefully, only when needed.
        </Typography>
        <Typography sx={{
          color: '#000 !important',
          fontSize: '1.1rem'
        }} variant='body1'>
          Antimicrobial resistance (AMR) occurs when these pathogens change over time and no longer respond to medicines, making infections harder to treat and increasing the risk of disease spread, severe illness and death. As a result, the medicines become ineffective and infections persist in the body, increasing the risk of spread to others. AMR is an increasingly serious public health threat, risking the effective prevention and treatment of infections.
        </Typography>
        <Typography sx={{
          color: '#000 !important',
          fontSize: '1.1rem'
        }} variant='body1'>
          AMR is estimated to be responsible for at least 700,000 deaths globally each year. In the US alone, at least 2.8 million people get an antibiotic-resistant infection and more than 35,000 people die, while in Europe, 33,000 people die from AMR each year. In India, 58,000 neonatal sepsis deaths were attributed to drug resistant infections in 2013, and in Japan, drug-resistant bacteria killed more than 8,000 people in 2017.
        </Typography>

        <Typography sx={{
          color: '#000 !important',
          fontSize: '1.1rem'
        }} variant='body1'>
          In China, AMR is expected to cause 1 million premature deaths annually by 2050; across Latin America and Africa, more than 800,000 deaths are expected to be due to AMR by 2050. OECD estimates that annual costs of AMR could be up to $3.5 Billion.
        </Typography>
        <Typography sx={{
          color: '#000 !important',
          fontSize: '1.1rem'
        }} variant='body1'>
          Addressing AMR requires concerted efforts by all stakeholders. We, as patients, patient caregivers, patient advocates and patient organisations have a goal to preserve effectiveness of antimicrobial medicines for as long as possible, so that they can be successfully used in treatment of infections, as well as to advocate for rational use and equitable access to existing and new anti-infective medicines and vaccines for everyone, everywhere.
        </Typography>
      </Container>
    </ThemeProvider>
  );
};

export default TextSection;
