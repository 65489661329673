import logo from './logo.svg';
import './App.css';
import Navigation from './components/Navigation/Navigation';
import MainHeader from './components/MainHeader/MainHeader';
import TextSection from './components/TextSection/TextSection';
import KeyIssues from './components/KeyIssues/KeyIssues';
import CTAStatement from './components/CTAStatement/CTAStatement';
import theme from './theme';
import { ThemeProvider } from '@emotion/react';
import AdvisoryGroup from './components/AdvisoryGroup/AdvisoryGroup';
import Footer from './components/Footer/Footer';
import Signatories from './components/Signatories/Signatories';
import Admin from './components/Admin/Admin';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/admin" element={<Admin />} />
          <Route path="/*" element={
            <div className="App">
              <Navigation />
              <MainHeader />
              <TextSection />
              <KeyIssues />
              <CTAStatement />
              <AdvisoryGroup />
              <Signatories />
              <Footer />
            </div>
          } />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
