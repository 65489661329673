import React, { useEffect, useState } from 'react'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Container, Grid, Box } from '@mui/material'
import Flag from 'react-world-flags'
// import signatoriesData from './signatoriesData'
import axios from 'axios'

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};


const Signatories = () => {
  const [flags, setFlags] = useState([])
  const [signatoriesData, setSignatoriesData] = useState({})
  const [imageData, setImageData] = useState([])

  const [countingIndividuals, setCountingIndividuals] = useState(0);
  const [countingOrganizations, setCountingOrganizations] = useState(0);
  
  const [isInView, setIsInView] = useState(false);
  const [isInViewOrg, setIsInViewOrg] = useState(false);

  const checkElementInView = () => {
    const element = document.getElementById("countingElement");
    const rect = element.getBoundingClientRect();
    const isInViewport = (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    setIsInView(isInViewport);

    const elementOrg = document.getElementById("countingElementOrg");
    const rectOrg = elementOrg.getBoundingClientRect();
    const isInViewportOrg = (
      rectOrg.top >= 0 &&
      rectOrg.left >= 0 &&
      rectOrg.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rectOrg.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    setIsInViewOrg(isInViewportOrg);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkElementInView);
    return () => {
      window.removeEventListener("scroll", checkElementInView);
    };
  }, []);

  useEffect(() => {
    let counterInd = 0, counterOrg = 0;
    const interval = setInterval(() => {
      if (isInView && counterInd < signatoriesData?.individualSignatories) {
        counterInd++;
        setCountingIndividuals(counterInd);
      }
      if (isInViewOrg && counterOrg < signatoriesData?.organizationSignatories) {
        counterOrg++;
        setCountingOrganizations(counterOrg);
      }
      if (counterInd >= signatoriesData?.individualSignatories && counterOrg >= signatoriesData?.organizationSignatories) {
        clearInterval(interval);
      }
    }, 10);
    return () => clearInterval(interval);
  }, [isInView, isInViewOrg]);

  useEffect(() => {
    const debouncedCheck = debounce(checkElementInView, 200);
    window.addEventListener("scroll", debouncedCheck);
    return () => {
      window.removeEventListener("scroll", debouncedCheck);
    };
  }, []);

  const imageContext = require.context('../../assets/organizations', false, /\.(png|jpe?g|svg)$/);
  const images = imageContext.keys().map(imageContext);

  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const newFlags = response.data.map((country) => {
          if (country.name.common === 'North Macedonia') {
            return {
              name: 'Macedonia',
              flag: country.flags.png
            };
          }
          return {
            name: country.name.common,
            flag: country.flags.png
          };
        });
        setFlags(newFlags);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios.get('https://repository-amrpatientalliance.herokuapp.com/get-statement-signatories')
    .then(res => {
      setSignatoriesData(res.data)
      setCountingIndividuals(res.data.individualSignatories)
    })
    .catch(err => {
      console.log(err);
    })
    axios.get('https://repository-amrpatientalliance.herokuapp.com/get-organization-logos')
    .then(res => {
      setImageData(res.data)
    })
    .catch(err => {
      console.log(err);
    })
  }, [])

  useEffect(() => {
    // console.log('flags', flags)
  }, [flags]);

  return (
    <Container sx={{
      margin: 'auto',
      marginTop: '25px',
      marginBottom: '25px',
    }}>
      <Typography variant='h1'>Signatories</Typography>
      <span className="elementor-divider-separator" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '12px' }}>
        <hr style={{ flex: 1, borderColor: '#000', borderRadius: '5px' }} />
        <div className="elementor-icon elementor-divider__element" style={{ margin: '0 10px' }}>
          <FontAwesomeIcon icon={faStar} aria-hidden="true" style={{
            fontSize: '1.5rem',
          }} />
        </div>
        <hr style={{ flex: 1, borderColor: '#000', borderRadius: '5px' }} />
      </span>
      <Grid
        container
        sx={{
          marginTop: '25px',
          marginBottom: '12px',
          '@media (max-width: 992px)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '25px',
          }
        }}
      >
        <Grid item xs={12} sm={6} md={6} sx={{
          '@media (max-width: 992px)': {
            minWidth: '100%',
          }
        }}>
          <Typography variant='h3'>Individuals</Typography>
          <Typography 
            variant='body1' 
            sx={{
              marginTop: '12px',
              marginBottom: '12px',
              color: '#44D2E3',
              fontFamily: '"Roboto", Sans-serif',
              fontSize: '25px',
              fontWeight: '600'
            }}
            id="countingElement"
          >
            {/* {signatoriesData?.individualSignatories} */}
            { countingIndividuals }
          </Typography>
          <Container sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
            marginTop: '12px',
            marginBottom: '12px',
            gap: '12px',
          }}>
            {
              signatoriesData?.individualsByCountry?.sort((a,b) => a.country.toLowerCase().localeCompare(b.country.toLowerCase())).map((signatory, index) => (
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  alignItems: 'stretch',
                }}>
                  <Box sx={{
                    background: '#F6F6F6',
                    '&:hover': {
                      background: 'rgba(202,248,128, .5)',
                    },
                    padding: '24px 48px',
                  }}>
                    {signatory.signatories.map((signatory, index) => (
                      <Typography key={`${index}-individual-signatory`} variant='body1' sx={{
                        color: '#67D58A',
                        fontWeight: '600'
                      }}>
                        {signatory}
                      </Typography>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      overflow: 'hidden',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                        transform: 'scale(1.5)', // Initially bigger
                        opacity: 0.5, // 50% opacity always
                        backgroundWidth: '100%',
                        backgroundPosition: 'top',
                      },
                      '&:hover::before': {
                        background: `url(${flags.find((flag) => flag.name === signatory.country)?.flag})`,
                        backgroundSize: '100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        transition: 'transform 2s ease-in-out',
                        transform: 'scale(1)', // Return to original size on hover
                        backgroundWidth: '100%',
                      },
                    }}
                  >
                    <Typography variant='body1' sx={{
                      alignSelf: 'center',
                      textAlign: 'center',
                      fontWeight: '600',
                      textShadow: '0 0 15px rgba(255, 255, 255, 0.5)'
                    }}>
                      {signatory.country}
                    </Typography>
                  </Box>
                </Box>
              ))
            }
          </Container>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{
          '@media (max-width: 992px)': {
            minWidth: '100%',
          }
        }}>
          <Typography variant='h3'>Organizations</Typography>
          <Typography 
            variant='body1' 
            sx={{
              marginTop: '12px',
              marginBottom: '12px',
              color: '#44D2E3',
              fontFamily: '"Roboto", Sans-serif',
              fontSize: '25px',
              fontWeight: '600'
            }}
            id="countingElementOrg"
          >
            {/* {
              signatoriesData?.organizationSignatories
            } */}
            {
              countingOrganizations
            }
          </Typography>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px'
          }}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`img-${index}`}
                width={150}
                style={{ transform: 'scale(1)', transition: 'transform 0.3s ease' }}
                onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.2)'}
                onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'} />
            ))}
            {
              imageData?.map((image, index) => (
                <img
                  key={100*index}
                  // src={`data:image/png;base64,${pendingApproval.organization_logo}`}
                  src={`data:image/png;base64,${image.organization_logo}`}
                  alt={`img-${index}`}
                  width={150}
                  style={{ transform: 'scale(1)', transition: 'transform 0.3s ease' }}
                  onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.2)'}
                  onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
                />
              ))
            }
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Signatories
