import { Container, Grid, Typography } from '@mui/material';
import React from 'react'
import AmrForm from './AmrForm';

const CTAStatement = () => {
  return (
    <div 
      id='callToAction'
      style={{
        width: '100%',
        background: '#E0E0E0',
        paddingTop: '25px'
      }}
    >
      <Container
      sx={{
        maxWidth: '90vw !important',
        paddingTop: '25px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '@media (max-width: 768px)': {
          flexDirection: 'column',
          alignItems: 'center',
          gap: '25px',
        }
      }}>
        <Grid container sx={{
          // background: '#fff',
          gap: '25px',
          padding: '1em',
        }}>
          <Typography variant='h1'>
            Call to Action
          </Typography>
          <Typography variant='h2' textAlign={'left'}>
            We, the patient community, pledge to support actions to address AMR through:
          </Typography>
          <Grid item sx={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '25px',
            textAlign: 'left',
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              alignItems: 'center',
              gap: '25px',
              textAlign: 'left'
            }
          }}>
            <Typography variant='h3'>
              Raising AMR Awareness And StrengthenHealth Literacy
            </Typography>
            <Typography variant='body1' sx={{
              maxWidth: '540px',
              '@media (max-width: 768px)': {
                maxWidth: "100%"
              }
            }}>
              Educate people who use antimicrobials and support the education of healthcare providers who prescribe and dispense them on actions they can take to reduce resistance, ensuring the right antimicrobial is prescribed according to clinical guidelines, and facilitating patient-clinical dialogues.
            </Typography>
          </Grid>
          <Grid item sx={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '25px',
            textAlign: 'left',
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              alignItems: 'center',
              gap: '25px',
              textAlign: 'left'
            }
          }}>
            <Typography variant='h3' sx={{
              '@media (max-width: 768px)': {
                alignSelf: 'flex-start',
              }
            }}>
              Advocating For Evidence-Based Solutions
            </Typography>
            <Typography variant='body1' sx={{
              maxWidth: '540px',
              '@media (max-width: 768px)': {
                maxWidth: "100%"
              }
            }}>
              Support the development and implementation of diagnostic tests to verify bacterial vs. viral, fungal or parasitic infection and support patient registries to gather antimicrobial usage data.
            </Typography>
          </Grid>
          <Grid item sx={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '25px',
            textAlign: 'left',
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              alignItems: 'center',
              gap: '25px',
              textAlign: 'left'
            }
          }}>
            <Typography variant='h3'>
              Ensuring a Patient-Centered Approach in Antimicrobial Development and access
            </Typography>
            <Typography variant='body1' sx={{
              maxWidth: '540px',
              '@media (max-width: 768px)': {
                maxWidth: "100%"
              }
            }}>
              Collaborate in efforts to research and develop new antimicrobials, vaccines, technologies, diagnostics and sustainable investment in AMR-relevant innovation, including promoting clinical trial involvement among a diverse patient community. Support equitable access to new antibiotics across all regions.
            </Typography>
          </Grid>
          <Grid item sx={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '25px',
            textAlign: 'left',
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flext-start',
              gap: '25px',
              textAlign: 'left'
            }
          }}>
            <Typography variant='h3' sx={{
              '@media (max-width: 768px)': {
                alignSelf: 'flex-start',
              }
            }}>
              Strengthening public health measures
            </Typography>
            <Typography variant='body1' sx={{
              maxWidth: '540px',
              '@media (max-width: 768px)': {
                maxWidth: "100%"
              }
            }}>
              Add AMR in infection prevention educational programs that include measures such as sanitation, hand washing, vaccination, food and water safety, and antibiotic use in agriculture – all of which can decrease the spread of microorganisms resistant to antimicrobial medicines (in schools, healthcare facilities, animal farms, etc.). Educate the general public on the proper disposal of antimicrobials to reduce environmental impact and provide a ‘patient voice’ to the development of consumer-discharge standards.
            </Typography>
          </Grid>
        </Grid>
        <Container sx={{
          padding: '1em',
          '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
            gap: '25px',
            textAlign: 'left'
          }
        }}>
          <Typography variant='h1'>
            Sign the Statement and Call to Action
          </Typography>
          <AmrForm />
        </Container>
      </Container>
      </div>
  )
};

export default CTAStatement;
