import React, { useState, useEffect } from "react";
import { Button, TextField, Typography } from "@mui/material";
import axios from 'axios';
// import validator from 'validator';

const Login = ({checkIfLoggedIn}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [token, setToken] = useState(null);

  
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const tokenCreated = localStorage.getItem('tokenCreated');
    if (storedToken && tokenCreated && Date.now() - tokenCreated < 1000 * 60 * 60) {
      setToken(storedToken);
      checkIfLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    // console.log('token: ', token)
    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('tokenCreated', Date.now());
    } else {
      // localStorage.removeItem('token');
      // localStorage.removeItem('tokenCreated');
    }
  }, [token]);

  const handleLogin = () => {
    if (password > '' && username > '') {
      setUsernameError(false);
      setPasswordError(false);
      // Perform login action here
      console.log('logging for now');
      axios.post('https://repository-amrpatientalliance.herokuapp.com/login', {
        username, password
      })
        .then((response) => {
          // console.log("success!", response);
          setToken(response.data.token);
          checkIfLoggedIn(true);
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setUsernameError(true);
      setPasswordError(true);
    }
  };
  return (
    <div style={{
      width: 'clamp(300px, 380px, 425px)',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px'
    }}>
      <Typography variant="h1">Login</Typography>
      <TextField
        id="username-field"
        name="username"
        label="Username"
        fullWidth
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        onKeyDown={(e) => e.code === 'Enter' ? handleLogin : null}
        error={usernameError}
        helperText={usernameError ? "Invalid username" : ""}
      />
      <TextField
        id="password-field"
        name="password"
        label="Password"
        type="password"
        fullWidth
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => e.code === 'Enter' ? handleLogin : null}
        error={passwordError}
        helperText={passwordError ? "Invalid password" : ""}
      />
      <Button
        variant="contained"
        onClick={handleLogin}
        sx={{ minWidth: '40%' }}
        fullWidth
      >
        Log in
      </Button>
    </div>
  );
};

export default Login;
