import React from 'react'
import { Button, Card, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import keyIssuesData from './keyIssuesData'
import back1 from '../../assets/back1.png'
import front1 from '../../assets/front1.png'

const useStyles = makeStyles({
  card: {
    position: 'relative',
    perspective: '1000px',
    height: '265px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover $inner': {
      transform: 'rotateY(180deg)'
    },
    border: 'none',
    boxShadow: 'none'
  },
  inner: {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
    margin: 'auto',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.5s ease',
  },
  front: {
    padding: '1em',
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    backfaceVisibility: 'hidden',
    alignItems: 'center',
    border: '1px solid #e2e2e2',
    borderRadius: '5px',
  },
  back: {
    padding: '1em',
    display: 'flex',
    transform: 'rotateY(180deg)',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    backfaceVisibility: 'hidden',
    alignItems: 'center',
    objectFit: 'contain',
  }
});


const KeyIssues = () => {
  const classes = useStyles();
  return (
    <Container sx={{
      marginTop: '25px',
      marginBottom: '25px'
    }}>
      <Typography variant='h1' sx={{
        color: '#000 !important',
        fontSize: '2rem',
        fontFamily: '"Allerta", Sans-serif !important',
        fontWeight: '600'
      }}>
        Key Issues
      </Typography>
      <Grid container spacing={2} sx={{
        marginTop: '12px',
        marginBottom: '12px'
      }}>
        {keyIssuesData.map((issue, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card className={classes.card} sx={{
              boxShadow: 'none',
            }}>
              <div className={classes.inner}>
                <div className={classes.front} style={{
                  backgroundImage: `url(${back1})`,
                  backgroundSize: 'cover',
                }}>
                  {issue.frontContent}
                </div>
                <div className={classes.back} style={{
                  backgroundImage: `url(${front1})`,
                  backgroundSize: 'cover',
                }}>
                  {issue.backContent}
                </div>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button variant='contained' sx={{
        marginTop: '12px'
      }}
      onClick={() => {
        window.open('http://amr.iapo-p4psobservatory.org/wp-content/uploads/2021/02/AMR-Patient-Consensus-Statement-finalDec2020.pdf', '_blank')
      }}>
        Read Full Statement
      </Button>
    </Container>
  );
};

export default KeyIssues;
