const keyIssuesData = [
  {
    frontContent: "Need for Increased AMR Awareness and Strengthened Health Literacy",
    backContent: "Low awareness of AMR as a public health crisis among patients and public. Education on antibiotic stewardship and appropriate use."
  },
  {
    frontContent: "Need for Improved Diagnosis and Evidence-Based Solutions",
    backContent: "For any infection (bacterial, fungal, viral, parasitic) use the appropriate antimicrobial\n AMR patient data registries\nAMR surveillance systems."
  },
  {
    frontContent: "Need for a Patient-Centered Approach In R&D and Access",
    backContent: "Economic challenges for development of new antibiotics.\n\nAvailability of and access to new medicines."
  },
  {
    frontContent: "Need to Strengthen Holistic Public Health Measures",
    backContent: "General infection prevention and control measures (e..g., vaccines, hygiene, etc.).\nEnvironmental impact and proper disposal of unused antibiotics."
  }
];

export default keyIssuesData;
