import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: '600',
color: '#4d4d4d'
    },
    h2: {
      fontSize: '2rem',
      color: '#000',
      fontWeight: '600',
      color: '#4d4d4d'
    },
    h3: {
      fontSize: '1.5rem',
      color: '#000',
    },
    body1: {
      color: '#000',
      contrastText: '#fff'
    },
    body2: {
      color: '#000',
      contrastText: '#fff'
    },
    button: {
      main: '#fff',
      color: '#fff',
      contrastText: '#fff'
    },
  },
  palette: {
    primary: {
      main: "#61CE70",
      contrastText: "#fff" //button text white instead of black
    },
    background: {
      default: "#394764"
    },
    success: {
      main: "#4CAF50",
      contrastText: "#fff"
    },
    danger: {
      main: "#f44336",
      contrastText: "#fff"
    },
    info: {
      main: "#2196F3",
      contrastText: "#fff"
    }
  },
});

export default theme;
