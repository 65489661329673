import React, { useState, useEffect } from 'react';
import { Button, Container, Paper, Typography, Box } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import * as XLSX from 'xlsx';
import PendingModal from './PendingModal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const CustomTabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AdminPanel = ({ checkIfLoggedIn, token }) => {
  const a11yProps = i => {
    return {
      id: `simple-tab-${i}`,
      'aria-controls': `simple-tabpanel-${i}`,
    };
  }

  const [value, setValue] = React.useState(0);
  const [signatories, setSignatories] = useState(null);
  const [signatoriesAdmin, setSignatoriesAdmin] = useState(null);
  const [emailSubscribers, setEmailSubscribers] = useState(null);
  const [pendingApprovals, setPendingApprovals] = useState(null);
  const baseUrl = 'https://repository-amrpatientalliance.herokuapp.com';
  const rowsPerPage = 5;
  const [emailIdsAdded, setEmailIdsAdded] = useState(false);

  const [pendingApproval, setPendingApproval] = useState(null);

  useEffect(() => {
    if (pendingApproval === null) {
      setPendingModalOpen(false);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const refreshData = () => {
    const urls = [
      '/get-statement-signatories-admin',
      '/get-email-subscribers',
      '/get-pending-approvals',
    ];
    const stateMapping = {
      '/get-statement-signatories': setSignatories,
      '/get-statement-signatories-admin': setSignatoriesAdmin,
      '/get-email-subscribers': setEmailSubscribers,
      '/get-pending-approvals': setPendingApprovals,
    };
    urls.forEach((url) => {
      fetchData(url, stateMapping[url]);
    });
  };

  const [pendingModalOpen, setPendingModalOpen] = useState(false);

  const [adminSelectedPending, setAdminSelectedPending] = useState([]);

  const fetchData = async (url, setState) => {
    try {
      const response = await axios.get(`${baseUrl}${url}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setState(response.data);
      // console.log(`${url}: success`, response.data);
    } catch (error) {
      console.log(`${url} rejected`, error);
    }
  };

  useEffect(() => {
    if (token) {
      refreshData();
    } else {
      checkIfLoggedIn(false);
    }
  }, [token]);

  useEffect(() => {
    // console.log('pendingApprovals: ', pendingApprovals)
  }, [pendingApprovals])

  const handleRowSelectionPending = (params) => {
    setAdminSelectedPending(prevState => [...prevState, params]);
    // console.log('selected: ', adminSelectedPending)
  };

  const deleteBulk = async () => {
    try {
      adminSelectedPending.forEach(async (row) => {
        await handleDelete(row.id);
      });
    } catch (error) {
      console.log('delete error', error);
    }
  }

  const handleDelete = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/delete-pending-approval/${id}`, {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      // console.log('delete success', response);
      refreshData();
    } catch (error) {
      console.log('delete error', error);
    }
  };

  const approveBulk = async () => {
    try {
      adminSelectedPending.forEach(async (row) => {
        await handleApprove(row.id);
      });
      // Fetch data again or update state
    } catch (error) {
      console.log('approve error', error);
    }
  };

  const handleApprove = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/approve-statement-signatory/${id}`, {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      refreshData();
      // console.log('approve success', response);
    } catch (error) {
      console.log('approve error', error);
    }
  };

  const exportToExcel = (nameOfTable) => {
    switch (nameOfTable) {
      case 'emailSubscribers':
        const data = [["Name", "Email", "Location"]]
        emailSubscribers.forEach((row) => {
          data.push([row.name, row.email, row.location]);
        });
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `AMR-Patient-Alliance-email-subscribers-${new Date().getTime()}.xlsx`);
        break;
      case 'signatoriesAdmin':
        const data2 = [["Type", "Name", "Country", "Email", "Approved", "Organization Name", "Scope"]]
        signatoriesAdmin.forEach((row) => {
          data2.push([row.type, row.name, row.country, row.email, row.approved ? 'Yes' : 'No', row.organization_name, row.scope]);
        }
        );
        const ws2 = XLSX.utils.aoa_to_sheet(data2);
        const wb2 = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb2, ws2, "Sheet1");
        XLSX.writeFile(wb2, `AMR-statement-signatories-${new Date().getTime()}.xlsx`);
        break;
      default:
        console.log('No table selected');
    }
  };

  const rejectBulk = () => {
    adminSelectedPending.forEach((row) => {
      if (pendingApprovals) {
        const newPendingApprovals = pendingApprovals.filter((pendingRow) => pendingRow.id !== row.id)
        setPendingApprovals(newPendingApprovals);
      }
    });
  };

  const handleReject = (id) => {
    const newPendingApprovals = pendingApprovals.filter((pendingRow) => {
      return pendingRow.id !== id;
    })
    setPendingApprovals(newPendingApprovals);
  }

  const handleRemoveFromTable = (id) => {
    const newPendingApprovals = pendingApprovals.filter((pendingRow) => {
      return pendingRow.id !== id;
    })
    setPendingApprovals(newPendingApprovals);
  }

  const handleApproveWithoutImage = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/approve-without-logo/${id}`, {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      // console.log('approve without image success', response);
      refreshData();
    } catch (error) {
      console.log('approve error', error);
    }
  }

  return (
    <Container sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '25px',
      // minHeight: '100vh',
    }}>
      <PendingModal
        open={pendingModalOpen}
        setOpen={setPendingModalOpen}
        pendingApproval={pendingApproval}
        token={token}
        handleDelete={handleDelete}
        handleReject={handleReject}
        handleApprove={handleApprove}
        handleApproveWithoutImage={handleApproveWithoutImage}
        handleRemoveFromTable={handleRemoveFromTable}
      />
      <Typography variant="h1" sx={{
        alignSelf: 'center',
        marginTop: '10px'
      }}>
        AMR Statement signatories managment dashboard
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Pending Approvals" {...a11yProps(0)} />
            <Tab label="Signatories" {...a11yProps(1)} />
            <Tab label="Email subscribers" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Paper elevation={1} sx={{ width: '100%', height: 'max-content', background: '#ddebf0' }}>
            {(pendingApprovals === null || pendingApprovals === undefined) ? <div></div>
              : pendingApprovals.length === 0 ? <h1>No pending approvals</h1> :
                <DataGrid
                  sx={{
                    background: '#fff'
                  }}
                  rows={pendingApprovals}
                  columns={[
                    // { field: 'id', headerName: 'ID', width: 100 },
                    { field: 'type', headerName: 'Type', flex: 1 },
                    { field: 'name', headerName: 'Name', flex: 1 },
                    { field: 'country', headerName: 'Country', flex: 1 },
                    { field: 'email', headerName: 'Email', flex: 1 },
                    {
                      field: 'open',
                      headerName: 'Details',
                      width: 100,
                      renderCell: (params) => (
                        <Button onClick={() => {
                          // console.log(params.row)
                          // handleOpen(params.row)
                          setPendingModalOpen(true);
                          setPendingApproval(params.row)
                        }}>
                          Details
                        </Button>
                      ),
                    },
                  ]}
                  // onRowClick={(e) => {
                  //   handleRowSelectionPending(e);
                  // }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 20]}
                  rowCount={pendingApprovals.length}
                  getRowId={(row) => row.id}
                  paginationMode="client"
                  autoHeight
                />}
          </Paper>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Paper elevation={1} sx={{ width: '100%', height: 'max-content', background: '#ddebf0' }}>
            {/* <Typography variant="h2" textAlign={'center'}>Statement Signatories - Admin</Typography> */}
            <DataGrid
              sx={{
                background: '#fff'
              }}
              rows={!signatoriesAdmin ? [] : signatoriesAdmin.map(el => ({ ...el, approved: el.approved ? 'Yes' : 'No' }))}
              columns={[
                // { field: 'id', headerName: 'ID', width: 100 },
                { field: 'type', headerName: 'Type', flex: 1 },
                { field: 'name', headerName: 'Name', flex: 1 },
                { field: 'country', headerName: 'Country', flex: 1 },
                { field: 'email', headerName: 'Email', flex: 1 },
                {
                  field: 'approved',
                  headerName: 'Approved',
                  flex: 1,
                },
                {
                  field: 'organization_name',
                  headerName: 'Organization Name',
                  flex: 1,
                },
                {
                  field: 'scope',
                  headerName: 'Scope',
                  flex: 1,
                }
              ]}
              // checkboxSelection
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              getRowId={(row) => row.id}
              pageSizeOptions={[5, 10, 20]}
              // onRowClick={e => {
              //   // handleRowSelection(e);
              // }}
              onPageChange={(params) => {
                // console.log(`Current page: ${params.page}`);
              }}
            />
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}>
              <Button onClick={() => { exportToExcel('signatoriesAdmin') }}>Export to Excel</Button>
            </div>
          </Paper>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Paper elevation={1} sx={{ width: '100%', height: 'max-content', background: '#ddebf0', marginBottom: '25px' }}>
            {/* <Typography variant="h2" textAlign={'center'}>Email Subscribers</Typography> */}
            <DataGrid
              sx={{
                background: '#fff',
              }}
              rows={emailSubscribers}
              columns={[
                // { field: 'id', headerName: 'ID', width: 100 },
                { field: 'name', headerName: 'Name', flex: 1 },
                { field: 'email', headerName: 'Email', flex: 1 },
                { field: 'location', headerName: 'Location', flex: 1 },
                // { field: 'date', headerName: 'Date', flex: 1}
              ]}
              // checkboxSelection
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 20]}
              getRowId={(row) => row.id}
            />
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}>
              <Button onClick={() => { exportToExcel('emailSubscribers') }}>Export to Excel</Button>
            </div>
          </Paper>

        </CustomTabPanel>
      </Box>
    </Container>
  );
};

export default AdminPanel;

// {
//   pendingApprovals && pendingApprovals.length > 0 &&
//   <Paper elevation={1} sx={{ width: '100%', height: 'max-content', background: '#ddebf0' }}>
//     {/* <Typography variant="h2" textAlign={'center'}>Pending Approvals</Typography> */}
    // <DataGrid
    //   sx={{
    //     background: '#fff'
    //   }}
    //   rows={pendingApprovals}
    //   columns={[
    //     { field: 'id', headerName: 'ID', width: 100 },
    //     { field: 'type', headerName: 'Type', flex: 1 },
    //     { field: 'name', headerName: 'Name', flex: 1 },
    //     { field: 'country', headerName: 'Country', flex: 1 },
    //     { field: 'email', headerName: 'Email', flex: 1 },
    //     {
    //       field: 'open',
    //       headerName: 'Open',
    //       width: 100,
    //       renderCell: (params) => (
    //         <Button onClick={() => {
    // //           console.log(params.row)
    //           // handleOpen(params.row)
    //           setPendingModalOpen(true);
    //           setPendingApproval(params.row)
    //         }}>
    //           Open
    //         </Button>
    //       ),
    //     },
    //   ]}
    //   // onRowClick={(e) => {
    //   //   handleRowSelectionPending(e);
    //   // }}
    //   initialState={{
    //     pagination: {
    //       paginationModel: {
    //         pageSize: 5,
    //       },
    //     },
    //   }}
    //   pageSizeOptions={[5, 10, 20]}
    //   rowCount={pendingApprovals.length}
    //   paginationMode="client"
    //   autoHeight
    // />
//   </Paper>
// }

// {
//   signatoriesAdmin && signatoriesAdmin.length > 0 &&
  // <Paper elevation={1} sx={{ width: '100%', height: 'max-content', background: '#ddebf0' }}>
  //   <Typography variant="h2" textAlign={'center'}>Statement Signatories - Admin</Typography>
  //   <DataGrid
  //     sx={{
  //       background: '#fff'
  //     }}
  //     rows={signatoriesAdmin}
  //     columns={[
  //       { field: 'id', headerName: 'ID', width: 100 },
  //       { field: 'type', headerName: 'Type', flex: 1 },
  //       { field: 'name', headerName: 'Name', flex: 1 },
  //       { field: 'country', headerName: 'Country', flex: 1 },
  //       { field: 'email', headerName: 'Email', flex: 1 },
  //       {
  //         field: 'approved',
  //         headerName: 'Approved',
  //         flex: 1,
  //       },
  //       {
  //         field: 'organization_name',
  //         headerName: 'Organization Name',
  //         flex: 1,
  //       },
  //       {
  //         field: 'scope',
  //         headerName: 'Scope',
  //         flex: 1,
  //       }
  //     ]}
  //     // checkboxSelection
  //     initialState={{
  //       pagination: {
  //         paginationModel: {
  //           pageSize: 5,
  //         },
  //       },
  //     }}
  //     pageSizeOptions={[5, 10, 20]}
  //     // onRowClick={e => {
  //     //   // handleRowSelection(e);
  //     // }}
  //     onPageChange={(params) => {
  // //       console.log(`Current page: ${params.page}`);
  //     }}
  //   />
  //   <div style={{
  //     display: 'flex',
  //     flexDirection: 'row',
  //     justifyContent: 'flex-end'
  //   }}>
  //     <Button onClick={() => { exportToExcel('signatoriesAdmin') }}>Export to Excel</Button>
  //   </div>
  // </Paper>
// }

// {
//   emailSubscribers !== null ?
    // <Paper elevation={1} sx={{ width: '100%', height: 'max-content', background: '#ddebf0', marginBottom: '25px' }}>
    //   <Typography variant="h2" textAlign={'center'}>Email Subscribers</Typography>
    //   <DataGrid
    //     sx={{
    //       background: '#fff',
    //     }}
    //     rows={emailSubscribers}
    //     columns={[
    //       { field: 'id', headerName: 'ID', width: 100 },
    //       { field: 'name', headerName: 'Name', flex: 1 },
    //       { field: 'email', headerName: 'Email', flex: 1 },
    //       { field: 'location', headerName: 'Location', flex: 1 },
    //       // { field: 'date', headerName: 'Date', flex: 1}
    //     ]}
    //     // checkboxSelection
    //     initialState={{
    //       pagination: {
    //         paginationModel: {
    //           pageSize: 5,
    //         },
    //       },
    //     }}
    //     pageSizeOptions={[5, 10, 20]}
    //   />
    //   <div style={{
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'flex-end'
    //   }}>
    //     <Button onClick={() => { exportToExcel('emailSubscribers') }}>Export to Excel</Button>
    //   </div>
    // </Paper>
//     :
//     'Loading...'
// }
