import React, { useEffect, useState } from 'react';
import { TextField, FormControl, FormControlLabel, Radio, RadioGroup, Checkbox, Button, Container, Box, InputLabel, Select, MenuItem, Alert, AlertTitle, CircularProgress } from '@mui/material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import { HCaptcha } from 'react-hcaptcha';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const AmrForm = () => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const HCAPTCHA_KEY = '08414c7f-fc1c-42c6-8bdc-1672c46bc3a3';
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedScope, setSelectedScope] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [file, setFile] = useState(null);
  const [radioValue, setRadioValue] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastName, setLastName] = useState('');

  const handleCaptchaVerify = token => {
    setError(null);
    setCaptchaToken(token);
  };

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    country: "",
    email: "",
    organization_name: "",
    organization_logo: null,
    scope: "",
    subscribed: false,
    // approved: false // it's false by default, no need to send this
  });

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setFormData({
      ...formData,
      type: e.target.value
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setFile(e.target.files[0]);

    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1];
      setFormData({
        ...formData,
        organization_logo: base64String
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setError(null);

    axios.get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const newCountries = response.data.map(country => {
          if (country.name.common === 'North Macedonia') {
            return 'Macedonia';
          }
          return country.name.common;
        });
        setCountryOptions(newCountries.sort());
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // console.log('formData: ', formData)
  }, [formData]);

  const handleSubmit = () => {
    if ([formData.name, formData.lastName, formData.email, formData.country].includes('')) {
      return setError('Please fill out all required fields')
    }

    axios.post('https://repository-amrpatientalliance.herokuapp.com/verify-captcha', { token: captchaToken }).then(res => {
      if (res.data.success) {
        if (formData.type === '') {
          formData.type = 'Individual';
        }
        setLoading(true);
        axios.post('https://repository-amrpatientalliance.herokuapp.com/catch-statement-signatory', formData)
          .then((response) => {
            setLoading(false);
            setError(null);
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
            setError(error);
          })
      } else {
        setError('Captcha verification failed. Please try again.')
      }
    })
  };

  return (
    <Container>
      {!loading && (
        <form style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '15px',
          marginTop: '25px'
        }}
        >
          {/* I sign on behalf */}
          <FormControl component="fieldset" sx={{
            alignSelf: 'flex-start'
          }}>
            <RadioGroup row>
              <FormControlLabel onClick={(e) => {
                handleRadioChange(e);
              }} value="Individual" control={<Radio />} label="Individual" />
              <FormControlLabel onClick={(e) => {
                handleRadioChange(e);
              }} value="Organisation" control={<Radio />} label="Organisation" />
              <FormControlLabel onClick={(e) => {
                handleRadioChange(e);
              }} value="Both" control={<Radio />} label="Both" />
            </RadioGroup>
          </FormControl>

          {/* Name */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '5px', width: '100%' }}>
            <TextField onChange={(e) => {
              setError(null);
              setFormData({
                ...formData,
                name: e.target.value
              });
            }} label="First Name" required sx={{ flexGrow: 1 }} InputLabelProps={{
              style: error === 'Please fill out all required fields' ? { color: 'red' } : {}
            }} />
            <TextField onChange={(e) => {
              setError(null);
              setLastName(e.target.value);
            }} onBlur={e => {
              setFormData({
                ...formData,
                name: `${formData.name} ${lastName}`
              });
              // console.log('formData: ', formData)
            }}
              label="Last Name"
              required
              sx={{ flexGrow: 1 }}
              InputLabelProps={{
                style: error === 'Please fill out all required fields' ? { color: 'red' } : {}
              }}
            />
          </Box>

          {/* Email */}
          <TextField onChange={e => {
            setError(null);
            setFormData({
              ...formData,
              email: e.target.value
            });
          }}
            label="Email"
            type="email"
            required
            fullWidth
            InputLabelProps={{
              style: error === 'Please fill out all required fields' ? { color: 'red' } : {}
            }}
          />

          {/* Country */}
          {/* <TextField label="Country" required fullWidth /> */}
          <FormControl fullWidth>
            <InputLabel id="country-label" shrink={selectedCountry !== ''} style={error === 'Please fill out all required fields' ? { color: 'red' } : {}}>Country *</InputLabel>
            <Select
              labelId="country-label"
              id="country"
              value={selectedCountry}
              onChange={(e) => {
                setError(null);
                setSelectedCountry(e.target.value);
                setFormData({
                  ...formData,
                  country: e.target.value
                });
              }}
              label="Country" // Link the label
              required
              sx={{
                textAlign: 'left'
              }}
            >
              {countryOptions.map((country, index) => (
                <MenuItem key={index} value={country}>{country}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Organisation Name */}
          <TextField onChange={e => {
            setFormData({
              ...formData,
              organization_name: e.target.value
            });
          }} label="Organisation Name" fullWidth />

          {/* Scope of Organisation */}
          <FormControl fullWidth>
            <InputLabel id="scope-label" shrink={selectedScope !== ''}>Scope of Organisation</InputLabel>
            <Select
              labelId="scope-label"
              id="scope"
              value={selectedScope}
              onChange={(e) => {
                setSelectedScope(e.target.value);
                setFormData({
                  ...formData,
                  scope: e.target.value
                });
              }}
              label="Scope of Organisation" // Link the label
              sx={{
                textAlign: 'left'
              }}
            >
              <MenuItem value="Global">Global</MenuItem>
              <MenuItem value="Regional">Regional</MenuItem>
              <MenuItem value="National">National</MenuItem>
              <MenuItem value="Local">Local</MenuItem>
            </Select>
          </FormControl>

          {/* I'd like to subscribe to amr newsletter */}
          <FormControlLabel
            control={<Checkbox />}
            label="Yes, I'd like to subscribe to AMR newsletter"
            sx={{
              alignSelf: 'flex-start'
            }}
            onChange={e => {
              setFormData({
                ...formData,
                subscribed: e.target.checked
              });
            }}
          />

          {/* Agreement */}
          <FormControlLabel
            control={<Checkbox required />}
            label="I agree my name/my organisation's logo to be listed on the website"
            sx={{
              alignSelf: 'flex-start'
            }}
            onChange={e => {
              setFormData({
                ...formData,
              });
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
            <InputLabel htmlFor="upload-image" style={{ display: 'flex', alignSelf: 'flex-start' }}>
              Upload your logo
            </InputLabel>

            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Button
                component="label"
                variant="contained"
                sx={{
                  width: 'fit-content', backgroundColor: '#1976d2', '&:hover': {
                    backgroundColor: '#1976d2'
                  }
                }}
                startIcon={<CloudUploadIcon />}
              >
                Upload image
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </Button>

              {file && <span>{file.name}</span>}
            </div>
          </Box>
          <div style={{ display: 'flex', width: 'fit-content', alignSelf: 'flex-start' }}>
            <HCaptcha sitekey={HCAPTCHA_KEY} onVerify={handleCaptchaVerify} />
          </div>
          {/* Submit Button */}
          <Button variant="contained" fullWidth sx={{ alignSelf: "flex-start", marginTop: '1.5vh', marginBottom: '3.5vh' }} onClick={handleSubmit}>
            Submit
          </Button>
        </form>
      )}
      {error && (
        <Alert variant="outlined" severity="error">
          Error: {error}
        </Alert>
      )}
      {loading && (
        <>
          <CircularProgress sx={{marginTop: '5vh'}} size={225} />
          <h2 style={{ color: '#61ce70' }}>Thank you. Your signature is being submitted.</h2>
        </>
      )}
    </Container>
  );
};

export default AmrForm;
