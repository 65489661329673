import { Container, Typography } from '@mui/material';
import React from 'react';
import './MainHeader.css';

const MainHeader = () => {
  return (
    <div className='MainHeader'>
      <Typography sx={{
        textTransform: 'uppercase',
        fontWeight: '600',
        lineHeight: '0.8em',
        fontSize: '3rem',
        color: '#fff !important',
        textShadow: '0 0 15px #000'
      }} variant='h1'>
        Antimicrobial Resistance
      </Typography>
      <Typography sx={{
        marginTop: '25px',
        fontSize: '2.24rem',
        fontWeight: '600',
        lineHeight: '1',
        color: '#fff !important',
        textShadow: '0 0 15px #000'
      }} variant='h2'>
        A Global Patient Consensus Statement and Call to Action on Antimicrobial Resistance
      </Typography>
    </div>
  );
};

export default MainHeader;
