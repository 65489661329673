import React from 'react'

const Footer = () => {
  return (
    <div
      style={{
        height: '100px',
        backgroundColor: '#222',
        color: '#999',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '5vw',
      }}
    >
      * Under UN Security Council Resolution 1244/99
    </div>
  );
};

export default Footer;
