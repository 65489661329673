import React, { useEffect, useState } from "react";
import { Box, Button, Container, Divider, TextField, Typography } from "@mui/material";
import Login from "./Login";
import AdminPanel from "./AdminPanel";

const Admin = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('tokenCreated') && Date.now() - localStorage.getItem('tokenCreated') < 1000 * 60 * 60) {
      setToken(localStorage.getItem('token'));
    }
  }, []);

  const checkIfLoggedIn = (loggedIn) => {
    if (!loggedIn) {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenCreated');
    }
  }
  if (token) {
    return (
      <AdminPanel 
        token={token} 
        checkIfLoggedIn={checkIfLoggedIn} 
      />
    );
  } else {
    return (
      <Container sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '25px',
          minHeight: '100vh'
      }}>
        <Login checkIfLoggedIn={checkIfLoggedIn} />
      </Container>
    )
  }
}

export default Admin;
