import Mariano from '../../assets/Mariano.webp'
import Neda from '../../assets/Neda.webp'
import Christine from '../../assets/Christine.webp'
import Thomas from '../../assets/Thomas.webp'
import Daniel from '../../assets/Daniel.webp'
import Danjuma from '../../assets/Danjuma.webp'

const advisoryGroupData = [
  { // the image locations will be local, which now are not, need to change this!
    image: Mariano,
    name: 'Mariano Votta',
    description: 'Cittadinanzattiva/Active Citizenship Network',
    link: 'http://amr.iapo-p4psobservatory.org/wp-content/uploads/2021/02/CV-Mariano-VottaAMR_Adv_Group.pdf'
  },
  {
    image: Neda,
    name: 'Neda Milevska-Kostova',
    description: 'International Alliance of Patients\' organizations',
    link: 'http://amr.iapo-p4psobservatory.org/wp-content/uploads/2021/02/CV-Neda-MilevskaAMR_Adv_Group.pdf'
  },
  {
    image: Christine,
    name: 'Christine Verini',
    description: 'Cancer Care',
    link: 'http://amr.iapo-p4psobservatory.org/wp-content/uploads/2021/02/CV_Christine-VeriniAMR_Adv_Group.pdf'
  },
  {
    image: Thomas,
    name: 'Thomass Heymann',
    description: 'Sepsis Alliance',
    link: 'http://amr.iapo-p4psobservatory.org/wp-content/uploads/2021/02/CV-Thomas-N-HeymannAMR_Adv_Group.pdf'
  },
  {
    image: Daniel,
    name: "Daniel Gallego",
    description: "Federación Nacional de Asociaciones ALCER [Association for the Fight Against Kidney Diseases]",
    link: 'http://amr.iapo-p4psobservatory.org/wp-content/uploads/2021/02/CV-Daniel-GallegoAMR_Adv_Group.pdf'
  },
  {
    image: Danjuma,
    name: "Danjuma Adda",
    description: "World Hepatitis Alliance",
    link: "http://amr.iapo-p4psobservatory.org/wp-content/uploads/2021/08/CV-Danjuma-Adda-converted.pdf"
  }
]

export default advisoryGroupData