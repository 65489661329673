import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const urls = [
  // '/get-statement-signatories',
  '/get-statement-signatories-admin',
  '/get-email-subscribers',
  '/get-pending-approvals',
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  maxWidth: '300px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PendingModal({ open, setOpen, pendingApproval, token, handleDelete, handleReject, handleApprove, handleApproveWithoutImage, handleRemoveFromTable }) {
  const handleClose = () => setOpen(false);
  // console.log('pendingApproval: ', pendingApproval)

  if (!open) return null;
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          {pendingApproval.name && <Typography id="keep-mounted-modal-title">Name: <strong>{pendingApproval.name}</strong></Typography>}
          {pendingApproval.email && <Typography id="keep-mounted-modal-description">Email: <strong>{pendingApproval.email}</strong></Typography>}
          {pendingApproval.country && <Typography id="keep-mounted-modal-description">Country: <strong>{pendingApproval.country}</strong></Typography>}
          {pendingApproval.organisation_name && <Typography id="keep-mounted-modal-description">Organization name: <strong>{pendingApproval.organisation_name}</strong></Typography>}
          {pendingApproval.scope && <Typography id="keep-mounted-modal-description">Organization Scope: <strong>{pendingApproval.scope}</strong></Typography>}
          {pendingApproval.type && <Typography id="keep-mounted-modal-description">Signatory type: <strong>{pendingApproval.type}</strong></Typography>}
          <Typography id="keep-mounted-modal-description">{pendingApproval.approved ? 'Approved' : 'Pending'}</Typography>
          <Typography id="keep-mounted-modal-description">{pendingApproval.subscribed ? 'Subscribed to Newsletter' : 'Not subscribed to Newsletter'}</Typography>
          {pendingApproval.organization_logo && <img src={`data:image/png;base64,${pendingApproval.organization_logo}`} alt="logo" width="150" style={{ marginTop: '8px', marginBottom: '8px' }} />}
          <div style={{
            marginTop: '8px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            <Button onClick={() => {
              handleApprove(pendingApproval.id);
              handleRemoveFromTable(pendingApproval.id);
              setOpen(false);
            }} color="success">
              Aprove
            </Button>
            <Button onClick={() => {
                handleApproveWithoutImage(pendingApproval.id);
                handleRemoveFromTable(pendingApproval.id);
                setOpen(false);
              }}
            >
              Aprove without image
            </Button>
            <Button onClick={() => {
              // handleReject(pendingApproval.id)
              setOpen(false)
            }} color="warning">
              Reject
            </Button>
            <Button onClick={() => {
              handleDelete(pendingApproval.id);
              handleRemoveFromTable(pendingApproval.id);
              setOpen(false);
            }} color="danger">
              Reject and Delete
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}