import React from 'react'
import { Card, CardMedia, Grid, Typography } from '@mui/material';
import { Container, ThemeProvider } from '@mui/system';
import theme from '../../theme';
import advisoryGroupData from './advisoryGroupData';


const AdvisoryGroup = () => {

  return (
    <ThemeProvider theme={theme}>
      <Container
        id='advisoryGroup'
        sx={{
          marginTop: '25px',
          marginBottom: '25px'
        }}
      >
        <Typography variant='h1'>AMR Global Patient Advisory Group</Typography>
        <Grid container spacing={2} sx={{
          marginTop: '12px',
        }}>
          {
            advisoryGroupData.map((member, index) => {
              return (
                <Grid key={index + member.name} item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      maxWidth: '300px',
                      minHeight: '528px',
                      padding: '20px'
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={member.image}
                      alt={member.name}
                      sx={{
                        minHeight: '375px'
                      }}
                    />
                    <Typography sx={{
                      justifySelf: 'flex-start'
                    }}>
                      {member.name}
                    </Typography>
                    <Typography>
                      {member.description}
                    </Typography>
                    <a href={member.link} target='_blank'>
                      Show Details
                    </a>
                  </Card>
                </Grid>
              )
            })
          }
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default AdvisoryGroup;
